$(function() {

  if(!Modernizr.touch) {
    $('.validate').validationEngine();
  } else {
    $('.validate').validationEngine({scroll: false});
  }

  var mapData = {
    title: 'Boons FIS Belgïe',
    lat: 50.960749,
    long: 3.549707,
    externUrl: 'https://www.google.be/maps/place/BOONS+FIS+-+Food+%26+Industrial+Supplies/@50.9603538,3.5468318,17z/data=!4m13!1m7!3m6!1s0x47c36cb49999b6bd:0xff0579f346c721b5!2sE3-laan+22,+9800+Deinze!3b1!8m2!3d50.9603504!4d3.5490205!3m4!1s0x47c36cb4f26b7393:0x6fa53b48a331c22a!8m2!3d50.9606179!4d3.5490544?hl=en'
  };

  if ($('#map').length > 0) {
    var map = addContactGoogleMaps('map', mapData.lat, mapData.long);
    addContactGoogleMapsMarker(map, 50.960749, 3.549707, 'Boons FIS Belgïe', 'https://www.google.be/maps/place/BOONS+FIS+-+Food+%26+Industrial+Supplies/@50.9603538,3.5468318,17z/data=!4m13!1m7!3m6!1s0x47c36cb49999b6bd:0xff0579f346c721b5!2sE3-laan+22,+9800+Deinze!3b1!8m2!3d50.9603504!4d3.5490205!3m4!1s0x47c36cb4f26b7393:0x6fa53b48a331c22a!8m2!3d50.9606179!4d3.5490544?hl=en');
  	addContactGoogleMapsMarker(map, 51.9152287,5.4318675, 'Boons FIS Nederland Newtonstraat 100', 'https://www.google.com/maps/place/Newtonstraat+100,+4004+KE+Tiel,+Netherlands/@51.9152287,5.4318675,17z/data=!3m1!4b1!4m5!3m4!1s0x47c657ed6e15d735:0x23b86cf72e356807!8m2!3d51.9152287!4d5.4344424?entry=ttu');
  }

});

function addContactGoogleMaps(container, latitude, longitude) {

  var zoom = 9,
      disable = true,
      scroll = false,
	  styledMap = new google.maps.StyledMapType(
		  [
			  {
				  "featureType": "all",
				  "elementType": "labels.text.fill",
				  "stylers": [
					  {
						  "saturation": 36
					  },
					  {
						  "color": "#333333"
					  },
					  {
						  "lightness": 40
					  }
				  ]
			  },
			  {
				  "featureType": "all",
				  "elementType": "labels.text.stroke",
				  "stylers": [
					  {
						  "visibility": "on"
					  },
					  {
						  "color": "#ffffff"
					  },
					  {
						  "lightness": 16
					  }
				  ]
			  },
			  {
				  "featureType": "all",
				  "elementType": "labels.icon",
				  "stylers": [
					  {
						  "visibility": "off"
					  }
				  ]
			  },
			  {
				  "featureType": "administrative",
				  "elementType": "geometry.fill",
				  "stylers": [
					  {
						  "color": "#fefefe"
					  },
					  {
						  "lightness": 20
					  }
				  ]
			  },
			  {
				  "featureType": "administrative",
				  "elementType": "geometry.stroke",
				  "stylers": [
					  {
						  "color": "#fefefe"
					  },
					  {
						  "lightness": 17
					  },
					  {
						  "weight": 1.2
					  }
				  ]
			  },
			  {
				  "featureType": "landscape",
				  "elementType": "geometry",
				  "stylers": [
					  {
						  "color": "#f5f5f5"
					  },
					  {
						  "lightness": 20
					  }
				  ]
			  },
			  {
				  "featureType": "poi",
				  "elementType": "geometry",
				  "stylers": [
					  {
						  "color": "#f5f5f5"
					  },
					  {
						  "lightness": 21
					  }
				  ]
			  },
			  {
				  "featureType": "poi.park",
				  "elementType": "all",
				  "stylers": [
					  {
						  "visibility": "off"
					  }
				  ]
			  },
			  {
				  "featureType": "road.highway",
				  "elementType": "geometry.fill",
				  "stylers": [
					  {
						  "color": "#ffffff"
					  },
					  {
						  "lightness": 17
					  }
				  ]
			  },
			  {
				  "featureType": "road.highway",
				  "elementType": "geometry.stroke",
				  "stylers": [
					  {
						  "color": "#ffffff"
					  },
					  {
						  "lightness": 29
					  },
					  {
						  "weight": 0.2
					  }
				  ]
			  },
			  {
				  "featureType": "road.arterial",
				  "elementType": "geometry",
				  "stylers": [
					  {
						  "color": "#ffffff"
					  },
					  {
						  "lightness": 18
					  }
				  ]
			  },
			  {
				  "featureType": "road.local",
				  "elementType": "geometry",
				  "stylers": [
					  {
						  "color": "#ffffff"
					  },
					  {
						  "lightness": 16
					  }
				  ]
			  },
			  {
				  "featureType": "transit",
				  "elementType": "geometry",
				  "stylers": [
					  {
						  "color": "#f2f2f2"
					  },
					  {
						  "lightness": 19
					  }
				  ]
			  },
			  {
				  "featureType": "water",
				  "elementType": "geometry",
				  "stylers": [
					  {
						  "color": "#e9e9e9"
					  },
					  {
						  "lightness": 17
					  }
				  ]
			  },
			  {
				  "featureType": "water",
				  "elementType": "geometry.fill",
				  "stylers": [
					  {
						  "color": "#bbe1e9"
					  }
				  ]
			  }
		  ],
		  {name: "Styled Map"}
	  ),
	  mapCenter = new google.maps.LatLng(51.530408, 4.476401),
      mapOptions = {
        zoom: zoom,
        panControl: true,
        zoomControl: disable,
        scaleControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        overviewMapControl: false,
        minZoom : 2,
        scrollwheel: scroll,
        center: mapCenter,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      },
      map = new google.maps.Map(document.getElementById(container), mapOptions);

  map.mapTypes.set('map_style', styledMap);
  map.setMapTypeId('map_style');

  google.maps.event.addDomListener(window, 'resize', function() {
    map.setCenter(mapCenter);
  });

  return map;
}

function addContactGoogleMapsMarker(map, latitude, longitude, title, externUrl) {
  var myLatlng = new google.maps.LatLng(latitude, longitude),
    markerIcon = {
      url: baseUrl + 'build/assets/images/marker.png',
      size: new google.maps.Size(100, 128),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(25, 64),
      scaledSize: new google.maps.Size(50, 64)
    },
    marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      icon: markerIcon
    });

  google.maps.event.addListener(marker, 'click', function() {
    window.open(externUrl, '_blank');
  });

  return marker;
}

// Recaptcha
window.esignContact = window.esignContact || {};

esignContact.init = function () {
  esignContact.formAjax();
};

/* Captcha */
esignContact.formAjax = function () {
  $('.form-ajax-recaptcha').submit(function(e) {
    e.preventDefault();
    var $form = $(this);


    if($form.hasClass('validate')){
      if ($form.validationEngine('validate') == false) {
        return false;
      }
    }

    if($form.find('[name="g-recaptcha-response"]').length && $form.find('[name="g-recaptcha-response"]').val() != ''){
      grecaptcha.reset();
    }

    grecaptcha.execute($form.find('.g-recaptcha').data('widgetid'));

    return false;
  });
};

/**
 * async ReCaptcha inladen en toevegen aan elk recaptcha blokje
 */
var onloadReCaptchaCallback = function() {
  $('.g-recaptcha').each(function(i,el){
    var attributes = {
      'sitekey'  : $(el).data('sitekey'),
      'size'     : $(el).data('size'),
      'callback' : $(el).data('callback')
    };

    var widgetid = grecaptcha.render(el, attributes);
    $(el).data('widgetid',widgetid);
  });
};

esignContact.recaptchaFormSend = function(form){

  $form = $(form);
  $form.find('input[type="submit"], button').attr('disabled', 'disabled');

  form_data = $form.serializeArray();

  $.post($form.attr('action'), form_data, function (data) {

    $('li').removeClass('error');
    var $result = $form.find('.result');

    if (data.errors === false) {
      if (data.redirect_url) {
        window.location.href = data.redirect_url;
      } else {
        $form.html(data.result);

        if(typeof ga === "function"){
          ga('send', 'pageview', $form.attr('action'));
        }
      }

    } else {
      $result.html(data.result);
      if (data.fields) {
        $.each(data.fields, function (i, field) {
          $('input[name="' + field + '"],textarea[name="' + field + '"]').addClass('error');
        });
      }
    }

    $form.find('input[type="submit"], button').removeAttr('disabled');

  }).fail(function(response) {
    alert('Error: ' + response.responseText);
  });

};

esignContact.recaptchaJobFormSend = function (form){
  var $form = $(form);
  jQuery.ajax({
    url: $form.attr('action'),
    data: new FormData(form),
    cache: false,
    contentType: false,
    processData: false,
    method: 'POST',
    success: function(data){

      $('li').removeClass('error');
      var $result = $form.find('.result');

      if (data.errors === false) {
        if (data.redirect_url) {
          window.location.href = data.redirect_url;
        } else {
          $form.html(data.result);

          if(typeof ga === "function"){
            ga('send', 'pageview', $form.attr('action'));
          }
        }

      } else {
        $result.html(data.result);
        if (data.fields) {
          $.each(data.fields, function (i, field) {
            $('input[name="' + field + '"],textarea[name="' + field + '"]').addClass('error');
          });
        }
      }

      $form.find('input[type="submit"], button').removeAttr('disabled');

    },
    error: function(response){
      console.log('Error: ' + response.responseText);
    }
  });
}

// initialize on docready
$(esignContact.init);
